import ProductPreview, { RawPreviewClient } from '../classes/ClientPreview';
export default class PreviewClientFactory {
    create(client: RawPreviewClient): ProductPreview {
        return new ProductPreview({
            first_name: client[0],
            last_name: client[1],
            company_name: client[2],
            honorific_prefix: client[3],
            email: client[4],
            phone: client[5],
            addresse: client[6],
            addresseSecondLine: client[7],
            postcode: client[8],
            city: client[9],
            country: client[10],
            vat: client[11],
        });
    }
}
