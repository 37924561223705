export interface RawPreviewClient {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: number;
    6: string;
    7: string;
    8: number;
    9: string;
    10: string;
    11: string;
}

export interface PreviewClient {
    first_name: RawPreviewClient[0];
    last_name: RawPreviewClient[1];
    company_name: RawPreviewClient[2];
    honorific_prefix: RawPreviewClient[3];
    email: RawPreviewClient[4];
    phone: RawPreviewClient[5];
    addresse: RawPreviewClient[6];
    addresseSecondLine: RawPreviewClient[7];
    postcode: RawPreviewClient[8];
    city: RawPreviewClient[9];
    country: RawPreviewClient[10];
    vat: RawPreviewClient[11];
}

export default class ClientPreview {
    private _first_name: string;
    private _last_name: string;
    private _company_name: string;
    private _honorific_prefix: string;
    private _email: string;
    private _phone: string;
    private _adresse: string;
    private _adresseSecondLine: string;
    private _postcode: number;
    private _city: string;
    private _country: string;
    private _vat: number;

    constructor(client: PreviewClient) {
        this._first_name = client.first_name;
        this._last_name = client.last_name;
        this._company_name = client.company_name;
        this._honorific_prefix = client.honorific_prefix;
        this._email = client.email;
        this._phone = client.phone;
        this._adresse = client.addresse;
        this._adresseSecondLine = client.addresseSecondLine;
        this._postcode = client.postcode;
        this._city = client.city;
        this._country = client.country;
        this._vat = client.vat;
    }

    get firstName() {
        return this._first_name;
    }

    set firstName(value: string) {
        this.firstName = value;
    }

    get lastName() {
        return this._last_name;
    }

    set lastName(value: string) {
        this.lastName = value;
    }

    get companyName() {
        return this._company_name;
    }

    set companyName(value: string) {
        this.companyName = value;
    }

    get honorificPrefix() {
        return this._honorific_prefix;
    }

    set honorificPrefix(value: string) {
        this.honorificPrefix = value;
    }

    get email() {
        return this._email;
    }

    set email(value: string) {
        this.email = value;
    }

    get phone() {
        return this._phone;
    }

    set phone(value: string) {
        this.phone = value;
    }

    get addresse() {
        return this._adresse;
    }

    set addresse(value: string) {
        this.addresse = value;
    }

    get addresseSecondLine() {
        return this._adresseSecondLine;
    }

    set addresseSecondLine(value: string) {
        this.addresseSecondLine = value;
    }

    get postcode() {
        return this._postcode;
    }

    set postcode(value: number) {
        this.postcode = value;
    }

    get city() {
        return this._city;
    }

    set city(value: string) {
        this.city = value;
    }

    get country() {
        return this._country;
    }

    set country(value: string) {
        this.country = value;
    }

    get vat() {
        return this._vat;
    }

    set vat(value: number) {
        this.vat = value;
    }
}
