<template>
    <div class="max-w-full overflow-x-auto nice-scrollbar">
        <table class="w-full max-w-full overflow-x-auto border-t border-l border-gray-200 nice-scrollbar">
            <thead>
                <tr class="border-b border-r border-gray-200 whitespace-nowrap">
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.first_name') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.last_name') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.company_name') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.honorific_prefix') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.email') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.phone') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.addresse') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.addresse_line_2') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.postcode') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.city') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.country') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.vat_number') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(client, index) in clientsPreviews"
                    :key="index"
                    class="border-b border-r border-gray-200"
                >
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.firstName ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.lastName ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.companyName ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.honorificPrefix ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.email ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.phone ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.addresse ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.addresseSecondLine ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.postcode ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.city ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.country ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.vat ?? '-' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts" setup>
    import ClientPreview, { RawPreviewClient } from '../../../app/common/classes/ClientPreview';
    import PreviewClientFactory from '../../../app/common/Factories/PreviewClientFactory';
    const props = defineProps({
  clients: null
});

    const clientsPreviews = ref<ClientPreview[]>([]);
    const init = () => {
        props.clients?.forEach((client: RawPreviewClient) => {
            const factory = new PreviewClientFactory();
            const formatedClient = factory.create(client);
            clientsPreviews.value.push(formatedClient);
        });
    };

    onMounted(() => {
        init();
    });
</script>
