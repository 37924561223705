<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { TENANT_SUBSCRIPTIONL_PLAN } from '../../../app/auth/enums/TenantSubscriptionPlan';

    defineProps({
  collapsed: { type: Boolean }
});
    const { tenant } = storeToRefs(useAuthStore());
    const isComboPlan = (plan: Enum<typeof TENANT_SUBSCRIPTIONL_PLAN>): boolean => {
        const comboPlans = [TENANT_SUBSCRIPTIONL_PLAN.COMBO_ESSENTIAL, TENANT_SUBSCRIPTIONL_PLAN.COMBO_PRO, TENANT_SUBSCRIPTIONL_PLAN.COMBO_ENTERPRISE];
        return comboPlans.includes(plan);
    };
</script>

<template>
    <div :class="$theme('layout.sidebar_links_container')">
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/company"
            icon="fa-helmet-safety"
            :label="$t('settings.sidebar.company')"
        />
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/profile"
            icon="fa-user"
            :label="$t('settings.sidebar.profile')"
        />
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/users"
            icon="fa-users"
            :label="$t('settings.sidebar.users')"
        />
        <LayoutSidebarSeparator />
        <LayoutSidebarLink
            v-if="tenant?.subscriptionPlan && !isComboPlan(tenant.subscriptionPlan)"
            :collapsed="collapsed"
            to="/settings/subscription"
            icon="fa-credit-card"
            :label="$t('settings.sidebar.subscription')"
        />
        <LayoutSidebarSeparator v-if="tenant?.subscriptionPlan && !isComboPlan(tenant.subscriptionPlan)" />
        <!-- <LayoutSidebarLink :collapsed="collapsed" to="/settings/inbox" icon="fa-inbox" :label="$t('settings.sidebar.inbox')" /> -->
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/outbox"
            icon="fa-inbox-out"
            :label="$t('settings.sidebar.outbox')"
        />

        <LayoutSidebarSeparator />
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/terms-of-service"
            icon="fa-file-contract"
            :label="$t('settings.sidebar.terms-of-service')"
        />
    </div>
</template>
