<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoicePaymentEndpoint from '~~/app/invoicing/endpoints/InvoicePaymentEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';
    import 'vue-slider-component/theme/antd.css';
    import InvoicePayment from '~/app/invoicing/models/InvoicePayment';
    import InvoicePaymentSchema from '~/app/invoicing/schemas/InvoicePaymentSchema';

    const { t } = useTranslation();
    const endpoint = EndpointFactory.make(ModelType.INVOICE_PAYMENTS) as InvoicePaymentEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    interface Fields {
        amount: number;
        title: string;
        paidAt: moment.Moment;
        paymentMethod: string;
        claimRemains: boolean;
    }
    const form = useForm<Fields>({
        amount: 0,
        title: t('invoicing.invoice_payment_default_title'),
        paidAt: moment(),
        paymentMethod: 'bank_transfer',
        claimRemains: true,
    });

    const { modalName, model } = useModelFormModal<InvoicePayment, Fields>(
        InvoicePayment,
        (payload?: FormModalPayload<InvoicePayment, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<InvoicePayment, Fields>) => {
        console.log('fill payment form modal', payload);
        if (payload?.fields && payload.fields.invoice) {
            invoice.value = payload.fields.invoice;
            form.set('amount', payload.fields.totalDue ?? invoice.value!.totalDue);
        }
        if (payload?.model) {
            form.fillWithModel(payload.model);
        }
    };

    const submit = async () => {
        const schema = new InvoicePaymentSchema({ attributes: form.data() });
        schema.attributes!.amount = Math.round(schema.attributes?.amount);
        schema.attributes!.claimRemains = !!form.get('claimRemains');
        if (model.value) {
            schema.id = model.value.getId();
        }
        schema.addToOneRelationship('invoice', ModelType.INVOICES, invoice.value!.getId());

        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.data) {
            useToasteoSuccess();
            useEvent('invoicing:invoice-payment:form-modal:close');
        }
    };
</script>

<template>
    <ModelFormModal
        :model="InvoicePayment"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('actions.update_payment') : $t('actions.record_payment') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormInput
                    :form="form"
                    input-name="title"
                />
                <FormTwoFields>
                    <template #field-1>
                        <FormPrice
                            :form="form"
                            input-name="amount"
                            :maximum-fraction-digits="2"
                            :max="invoice?.totalDue ?? null"
                        />
                        <!-- <div
                            class="mb-2 -mt-5 text-xs text-gray-600 cursor-pointer hover:text-black hover:underline"
                            @click.prevent="form.set('amount', invoice.totalDue)"
                            v-if="form.get('amount') !== invoice?.totalDue && ! model"
                        >
                            <p>Fill form with the remaining due.</p>
                        </div> -->
                    </template>
                    <template #field-2>
                        <FormDate
                            :form="form"
                            input-name="paidAt"
                        />
                    </template>
                </FormTwoFields>
                <div
                    v-if="!model && form.get('amount') > invoice?.totalDue"
                    class="flex items-center p-4 mb-8 space-x-4 text-sm text-white bg-orange-600 rounded"
                >
                    <div>
                        <i class="text-2xl fa-regular fa-triangle-exclamation" />
                    </div>
                    <div>
                        <span>{{ $t('invoicing.invoice_payment_form_modal.amount_too_high') }}</span>
                    </div>
                </div>
                <FormCheckbox
                    v-if="invoice?.totalDue && form.get('amount') < invoice?.totalDue"
                    :form="form"
                    input-name="claimRemains"
                />
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('invoicing:invoice-payment:form-modal:close')"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :class-name="$theme('button.style.green')"
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                    >
                        {{ model ? $t('actions.update_payment') : $t('actions.record_payment') }}
                    </LoadingButton>
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
