<script setup lang="ts">
    import InvoiceTemplate from '~/app/invoicing/models/InvoiceTemplate';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';

    const endpoint = EndpointFactory.make(ModelType.INVOICE_TEMPLATES);
    const invoiceTemplate = reactiveModel<InvoiceTemplate>(null);
    const replicatedInvoiceTemplate = reactiveModel<InvoiceTemplate>(null);
    const success = ref(false);
    const form = useForm<{
        documentType: string;
        title: string;
    }>({
        documentType: 'invoice',
        title: '',
    });

    useModal(
        'invoicing:invoice-templates:replicate',
        (payload: InvoiceTemplate) => {
            invoiceTemplate.value = payload;
            const documentType = invoiceTemplate.value?.documentType || 'invoice';
            const title = invoiceTemplate.value?.title ? `${invoiceTemplate.value?.title} copy` : '';
            form.set('documentType', documentType);
            form.set('title', title);
        },
        () => {
            success.value = false;
            invoiceTemplate.value = replicatedInvoiceTemplate.value = null;
            form.reset();
        }
    );

    const submit = async () => {
        const response = await form.loadUntil(
            endpoint.replicate(invoiceTemplate.value!.getId(), {
                documentType: form.get('documentType'),
                title: form.get('title'),
            })
        );

        if (response.data) {
            replicatedInvoiceTemplate.value = response.data;
            useToasteoSuccess();
            useEvent('invoicing:invoice-templates:replicate:close');
            form.reset();
        }
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice-templates:replicate"
    >
        <template v-if="invoiceTemplate">
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">{{ $t('actions.duplicate') }}</h2>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <FormSelect
                        :form="form"
                        input-name="documentType"
                    >
                        <option
                            v-for="documentType in ['quote', 'invoice', 'credit-note', 'delivery-slip', 'order-slip']"
                            :key="documentType"
                            :value="documentType"
                        >
                            {{ $t(`invoice_document_type.${documentType}`) }}
                        </option>
                    </FormSelect>
                    <FormInput
                        :form="form"
                        input-name="title"
                    />
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('invoicing:invoice-templates:replicate:close')"
                            >{{ $t('actions.cancel') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.duplicate') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
