<script setup lang="ts">
    import { onClickOutside } from '@vueuse/core';
    import { v4 as uuidv4 } from 'uuid';
    import { z } from 'zod';
    import { isEmail } from '~/utils/string-helpers';

    const emit = defineEmits(["changed"]);

    const props = defineProps({
  value: null,
  placeholder: null,
  multiple: { type: Boolean, default: false }
});

    const loading = ref(false);
    const { search } = useMeilisearch();
    const meilisearchContacts = ref();

    const searchContacts = async () => {
        loading.value = true;

        const results = await search({
            model: 'contacts',
            query: currentInput.value,
            limit: 5,
        });
        meilisearchContacts.value = results.hits;
        loading.value = false;
        if (z.string().email().safeParse(currentInput.value).success) {
            // it should push an object meilisearch contact in the array ref
            meilisearchContacts.value.push({
                email: currentInput.value,
            });
        }
    };

    const input: Ref<HTMLElement | null> = ref(null);
    const email = ref(props.value);
    const editing = ref(false);
    const currentInput = ref('');

    onClickOutside(input, (event) => (editing.value = false));

    const emailBadges = computed(() => {
        return email.value
            .split(',')
            .map((email) => email.trim())
            .filter((trimmedEmail) => trimmedEmail !== '')
            .map((validEmail) => ({
                id: uuidv4(),
                email: validEmail,
            }));
    });

    const edit = () => {
        editing.value = true;
        nextTick(() => {
            input.value!.focus();
        });
    };

    const remove = (uuid: string) => {
        const updatedEmails = emailBadges.value
            .filter((emailBadge) => emailBadge.id !== uuid) // Filter out the badge with the matching UUID
            .map((emailBadge) => emailBadge.email);
        email.value = updatedEmails.join(',');
        emit('changed', email.value);
    };

    const validEmail = () => {
        if (!props.multiple) {
            return isEmail(currentInput.value);
        }

        const emails = currentInput.value.split(',').map((email) => email.trim());
        return emails.every((email) => isEmail(email)); // Ensure all emails are valid
    };

    const ensureUniqueEmail = (emails: array) => {
        const uniqueNewEmails = emails.filter((newEmail) => !email.value.includes(newEmail));
        if (!uniqueNewEmails.length) {
            currentInput.value = '';
            return;
        }
        return uniqueNewEmails;
    };

    const confirmEmails = () => {
        if (!validEmail()) {
            currentInput.value = '';
            return;
        }

        const emails = currentInput.value
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email !== '');

        const newEmails = ensureUniqueEmail(emails);
        if (!props.multiple) {
            email.value = newEmails[0]; // Handle single email
        } else {
            email.value = email.value
                ? `${email.value},${newEmails.join(',')}` // Append to existing emails
                : newEmails.join(','); // Handle first entry
        }
        editing.value = false;
        emit('changed', email.value);
        currentInput.value = '';
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key !== 'Enter' && event.key !== ',') return;
        confirmEmails(); // Confirm emails when Enter or a comma is pressed
    };

    const select = (meilisearchContact: any) => {
        currentInput.value = meilisearchContact.email;
        confirmEmails();
        editing.value = false;
        currentInput.value = '';
    };

    onMounted(() => {
        searchContacts();
    });
</script>

<template>
    <div
        class="relative"
        :class="emailBadges.length > 1 ? 'flex flex-col space-y-2' : 'flex items-center space-x-2'"
    >
        <div class="flex flex-wrap items-center gap-2 group">
            <!-- <SubmenuEmptyContent v-if="!meilisearchContacts.length" /> -->

            <FormEmailBadgeItem
                v-for="badge in emailBadges"
                :key="badge.id"
                :badge="badge"
                @remove="remove"
            />
        </div>
        <button
            v-if="!editing"
            class="border border-gray-200 py-1 px-2 rounded-md text-sm text-gray-400 w-fit items-center hover:bg-green-500 hover:text-white"
            @click.prevent="edit"
        >
            <i class="fa-solid fa-circle-plus pr-1"></i> Ajouter
        </button>
        <input
            v-if="editing"
            ref="input"
            v-model="currentInput"
            class="w-full p-2 text-sm text-red-600 outline-none focus:text-black"
            type="text"
            @input.prevent.stop="searchContacts()"
            @keydown="handleKeyDown"
            @blur="confirmEmails"
        />

        <div v-if="editing || currentInput !== ''">
            <div class="flex flex-col absolute left-0 top-full bg-white rounded-md shadow-md z-50 w-full min-w-[400px]">
                <div
                    v-for="meilisearchContact in meilisearchContacts"
                    :key="meilisearchContact.uuid"
                    :class="[$theme('contextmenu.link', 'cursor-pointer')]"
                >
                    <div
                        class="flex space-x-2 items-center"
                        @click.prevent="select(meilisearchContact)"
                    >
                        <div
                            v-if="meilisearchContact.contact_type === 'client'"
                            class="text-sm bg-center bg-cover rounded-full cursor-pointer bg-neutral-200 w-8 h-8 lg:h-10 lg:w-10 text-center items-center flex justify-center"
                        >
                            <i class="fa-solid fa-user text-lg"></i>
                        </div>
                        <div
                            v-if="meilisearchContact.contact_type === 'supplier'"
                            class="text-sm bg-center bg-cover rounded-full cursor-pointer bg-neutral-200 w-8 h-8 lg:h-10 lg:w-10 text-center items-center flex justify-center"
                        >
                            <i class="fa-solid fa-building-memo text-lg"></i>
                        </div>
                        <div
                            v-if="!meilisearchContact?.display_name"
                            class="text-sm bg-center bg-cover rounded-full cursor-pointer bg-neutral-200 w-8 h-8 lg:h-10 lg:w-10 text-center items-center flex justify-center"
                        >
                            <i class="fa-solid fa-user-slash text-lg"></i>
                        </div>
                        <div class="space-y-1">
                            <span
                                v-if="meilisearchContact?.display_name"
                                class="text-sm block capitalize font-medium"
                                >{{ `${meilisearchContact.display_name}` }}</span
                            >
                            <span class="text-sm block">{{ `${meilisearchContact.email}` }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
